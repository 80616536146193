import React from 'react';
import { Route, Redirect } from "react-router-dom";
import {useSelector} from "react-redux";
import {useAnchorWallet} from "@solana/wallet-adapter-react";

const NftGuardedRoute = ({ path, exact, children }) => {
    const auth = useSelector(state => state.verified);
    const wallet = useAnchorWallet()
    return auth && wallet ? (
        <Route path={path} exact={exact}>
            {children}
        </Route>
    ) : (
        <Redirect to="/" />
    );
};


export default NftGuardedRoute;