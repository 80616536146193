import React, {useEffect, useMemo, useState} from 'react';
import hideoutTransition from "../../images/Hideout_Transition.mp4";
import hideoutLanding from "../../images/Hideout_Landing_Page.mp4";
import profile from "../../images/profile.png";
import arrowIcon from "../../images/arrow_icon.png";
import hideoutLandingPlaceholder from "../../images/Hideout_Landing.png"
import dashboardPlaceholder from "../../images/Dashboard.png"
import dashboard from "../../images/Dashboard.webm";
import hideoutDashboardPlaceholder from "../../images/video/Transition_Out_Dashboard_to_Landing_Page_Hideout.webm";
import {VideoMap} from "react-video-map/dist/index";
import "./hideout.css"
import axios from "axios";
import * as anchor from "@project-serum/anchor";
import {ConnectionProvider, useAnchorWallet, WalletProvider,} from "@solana/wallet-adapter-react";
import styled from "styled-components";
import Chart from "react-apexcharts";
import "../../App.css";
import {Table} from 'antd';
import {useSelector} from "react-redux";
import {
    WalletDialogButton,
    WalletDisconnectButton,
} from "@solana/wallet-adapter-material-ui"
import {  programs } from '@metaplex/js';
import {PublicKey} from "@solana/web3.js";
const { metadata: { Metadata } } = programs;
const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST;
const connection = new anchor.web3.Connection(rpcHost);
const network = process.env.REACT_APP_SOLANA_NETWORK;
const apiUrl = process.env.REACT_APP_NEO_NINJA_API

const ConnectButton = styled(WalletDialogButton)`
  font-weight: bolder;
  cursor: pointer;
  border: none;
  box-shadow: 7px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 4px;
  color: #ffffff;
  transition-property: opacity;
  transition-duration: 0.16s;
  transition-timing-function: ease-in;
  width: 224px;
  height: 70px;
`;


const DisconnectButton = styled(WalletDisconnectButton)`
  font-weight: bolder;
  cursor: pointer;
  border: none;
  box-shadow: 7px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 4px;
  color: #ffffff;
  transition-property: opacity;
  transition-duration: 0.16s;
  transition-timing-function: ease-in;
  width: 224px;
  height: 70px;
`;

const sortByTimeAscending = (a, b) => a.time > b.time ? 1 : -1;
const Hideout = () => {
    const [totalTVL, setTotalTVL] = useState('0')
    const [holdersCount, setHoldersCount] = useState('0')
    const [totalRewards, setTotalRewards] = useState('0')
    const [emissions, setEmissions] = useState([])
    const [holders, setHolders] = useState([])
    const [nftProfile, setNftProfile] = useState(profile)
    const [royalties, setRoyalties] = useState([])
    const [chartView, setChartView] = useState("emissions")
    const walletNfts = useSelector(state => {
        return state.tokens
    })
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), [])
    const wallet = useAnchorWallet();
    emissions.sort(sortByTimeAscending)
    royalties.sort(sortByTimeAscending)
    const [areaButton, setAreaButton] = useState(true);
    useEffect(() => {
        axios.get(apiUrl + "/api/tvl").then(response => {
            setTotalTVL(formatNumberToUSD(response.data.tvlAmountUi))
        })
    }, [wallet])

    useEffect(() => {
        axios.get(apiUrl + "/api/emissions").then(response => {
            setEmissions(response.data)
        })
    }, [wallet])

    useEffect(() => {
        axios.get(apiUrl + "/api/royalties").then(response => {
            setRoyalties(response.data)
        })
    }, [wallet])

    useEffect(() => {
        axios.get(apiUrl + "/api/holders").then(response => {
            setHolders(response.data)
            setHoldersCount(response.data.length >= 0 ? new Set(response.data.map(holder => holder.owner)).size.toString() : "-")
            forceUpdate()
        })
    }, [wallet])

    const loadNftData = async () => {
        if (walletNfts.length <= 0)
            return

        let metadata = await Metadata.load(connection, new PublicKey(walletNfts.at(0).metadata))
        const extra = await axios.get(metadata.data.data.uri)
        setNftProfile(extra.data.image)
    }
    useEffect(() => {
        loadNftData().catch(console.error)
    }, [walletNfts, wallet])

    const columns = [
        {
            title: '',
            dataIndex: 'name',
            render(text) {
                return {
                    props: {
                        style: {color: "#ffffff"}
                    }
                    ,
                    children: (<div className='table-name batmfa-font'>
                        {text}
                    </div>)
                }
            }
        },
        {
            title: '24HR',
            dataIndex: 'day',
            sorter: {
                compare: (a, b) => a.day - b.day,
                multiple: 3,
            },
            render(text) {
                return {
                    props: {
                        style: {color: "#ffffff"}
                    }
                    ,
                    children: (<span>
                        ${text}
                    </span>)
                }
            }
        },
        {
            title: '7DAYS',
            dataIndex: 'week',
            sorter: {
                compare: (a, b) => a.week - b.week,
                multiple: 2,
            },
            render(text) {
                return {
                    props: {
                        style: {color: "#ffffff"}
                    }
                    ,
                    children: (<span>
                        ${text}
                    </span>)
                }
            }
        },
        {
            title: '30DAYS',
            dataIndex: 'month',
            sorter: {
                compare: (a, b) => a.month - b.month,
                multiple: 1,
            },
            render(text) {
                return {
                    props: {
                        style: {color: "#ffffff"}
                    }
                    ,
                    children: (<span>
                        ${text}
                    </span>)
                }
            }
        },
        {
            title: 'ALL-TIME',
            dataIndex: 'alltime',
            render(text) {
                return {
                    props: {
                        style: {color: "#ffffff"}
                    }
                    ,
                    children: (<span>
                        ${text}
                    </span>)
                }
            }
        },
    ];

    const emissionsForDuration = (duration) => {

        let spanEmissions = emissions
            .filter(emission => emission.time >= (new Date().getTime() / 1000) - duration)
        if (spanEmissions.length <= 0) return 0
        return Math.abs(spanEmissions.at(0).uiAmountUsdcCumulative - emissions.at(-1).uiAmountUsdcCumulative || 0).toFixed(0)
    }

    const royaltiesForDuration = (duration) => {
        return (royalties
            .filter(royalty => royalty.time >= (new Date().getTime() / 1000) - duration)
            .reduce((acc, nxt) => acc + nxt.uiAmountUsdc, 0) || 0).toFixed(0)
    }

    const dailyEmissions = emissionsForDuration(60 * 60 * 24)
    const weeklyEmissions = emissionsForDuration(60 * 60 * 24 * 7)
    const monthlyEmissions = emissionsForDuration(60 * 60 * 24 * 30)

    const dailyRoyalties = royaltiesForDuration(60 * 60 * 24)
    const weeklyRoyalties = royaltiesForDuration(60 * 60 * 24 * 7)
    const monthlyRoyalties = royaltiesForDuration(60 * 60 * 24 * 30)

    const allTimeRoyalties = (royalties
        .reduce((acc, nxt) => acc + nxt.uiAmountUsdc, 0) || 0).toFixed(0)

    const allTimeEmissions = (() => {

        if (emissions.length <= 0) return 0
        return (emissions.at(-1).uiAmountUsdcCumulative || 0).toFixed(0)
    })()
    useEffect(() => {
        if (holders.length <= 0) return
        //let multiplier = walletNfts.length > 0 ? walletNfts.length : 1
        // setTotalRewards(formatNumberToUSD(((Number(allTimeRoyalties) + Number(allTimeEmissions)) /holders.length) * multiplier))
        setTotalRewards(formatNumberToUSD((Number(allTimeRoyalties) + Number(allTimeEmissions)) / 2))

    }, [allTimeEmissions, allTimeRoyalties, holders, holdersCount])
    const data = [
        {
            key: '1',
            name: 'EMISSIONS',
            day: dailyEmissions,
            week: weeklyEmissions,
            month: monthlyEmissions,
            alltime: allTimeEmissions,

        },
        {
            key: '2',
            name: 'ROYALTIES',
            day: dailyRoyalties,
            week: weeklyRoyalties,
            month: monthlyRoyalties,
            alltime: allTimeRoyalties,
        },
        {
            key: '3',
            name: 'TOTAL',
            day: Number(dailyEmissions) + Number(dailyRoyalties),
            week: Number(weeklyEmissions) + Number(weeklyRoyalties),
            month: Number(monthlyRoyalties) + Number(monthlyEmissions),
            alltime: Number(allTimeEmissions) + Number(allTimeRoyalties)
        },

    ];

    const state = chartView === "emissions" ? {
        series: [{
            name: 'Emissions',
            data: emissions.map(emission => emission.uiAmountUsdcCumulative.toFixed(2))
        }],
        options: {
            chart: {
                height: 350,
                type: 'area'
            },
            dataLabels: {
                enabled: false
            },
            colors: ['#01fff3'],
            stroke: {
                curve: 'smooth',
            },
            xaxis: {
                type: 'datetime',
                categories: emissions.map(emission => new Date(emission.time * 1000).toLocaleString()),
                labels: {
                    style: {
                        colors: '#000000B5',
                        fontSize: '16',
                        fontWeight: 600
                    }
                }
            },
            yaxis: {
                labels: {
                    style: {
                        colors: '##000000B5',
                        fontSize: '16',
                        fontWeight: 600
                    }
                }
            },
            tooltip: {
                enabled: false
            },
            fill: {
                colors: ['#36dbf4', '#1ed8e9', '#27b090']
            }
        },
    } : {
        series: [{
            name: 'Royalties',
            data: royalties.map(royalty => royalty.uiAmountUsdcCumulative.toFixed(2))
        }],
        options: {
            chart: {
                height: 350,
                type: 'area'
            },
            dataLabels: {
                enabled: false
            },
            colors: ['#01fff3'],
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'datetime',
                categories: royalties.map(royalty => new Date(royalty.time * 1000).toLocaleString()),
                labels: {
                    style: {
                        colors: '#ffffff'
                    }
                }
            },
            yaxis: {
                labels: {
                    style: {
                        colors: '#ffffff'
                    }
                }
            },
            tooltip: {
                enabled: false
            },
            fill: {
                colors: ['#36dbf4', '#1ed8e9', '#27b090']
            }
        }
    }
    const landingHeaderArea = {
        width: "90%",
        height: "15%",
        left: "5%",
        top: "10%",
        style: {
            borderRadius: "2px",
        },
        classname: "hideout-header",
        render: (area, index, totalTvl, totalHolders, rewards) => {

            return (
                <div>
                    <div className={"hideout-header-divider"}>
                        <div className={"hideout-header-info"}>
                            <div className="hideout-header-image background-light-blue">
                                <img src={nftProfile} alt="profile"></img>
                            </div>
                            <div className='hideout-header-description-container'>
                                <div
                                    className='background-light-blue display-flex space-betweeen hideout-header-description-container-first'>
                                    <div className='display-flex padding-right'>
                                        <p className='hideout-header-value-description batmfa-font'>Total Value Locked
                                            : </p>
                                        <p className='hideout-header-value batmfa-font'>{totalTvl !== '0' ? totalTVL : "-"}</p>
                                    </div>
                                    <div className='display-flex padding-right'>
                                        <p className='hideout-header-value-description batmfa-font'>Total HOLDERS : </p>
                                        <p className='hideout-header-value batmfa-font'>{totalHolders !== '0' ? totalHolders : '-'}</p>
                                    </div>
                                    <div className='display-flex padding-right-end'>
                                        <p className='hideout-header-value-description batmfa-font'>Total REWARDS : </p>
                                        <p className='hideout-header-value batmfa-font'>{rewards !== '0' ? rewards : '-'}</p>
                                    </div>
                                </div>
                                <div
                                    className='background-light-blue display-flex space-betweeen hideout-header-description-container-second'>
                                    <div className='neo-ninja'>
                                        <p className='hideout-header-value batmfa-font border-after'>{walletNfts && walletNfts.length > 0 ? walletNfts.at(0).name : (<></>)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='button-container'>
                            {wallet ? (
                                <DisconnectButton className="disconnect-wallet-button" ></DisconnectButton>
                            ) : (
                                <ConnectButton className="connect-wallet-button"> </ConnectButton>
                            )}
                        </div>
                    </div>
                    <div className='hideout-mobile-divider'>
                        <div style={{width: "100%"}}>
                            <div className={"hideout-header-info"} style={{width: "100%"}}>
                                <div className="hideout-header-image background-light-blue">
                                    <img src={nftProfile} alt="profile"></img>
                                </div>
                                <div className='hideout-header-description-container' style={{width: "100%"}}>
                                    <div className='background-light-blue hideout-header-description-container-first'>
                                        <div className='display-flex padding-right'>
                                            <p className='hideout-header-value-description batmfa-font'>Total Value
                                                Locked : </p>
                                            <p className='hideout-header-value batmfa-font'>{totalTvl !== '0' ? totalTVL : "-"}</p>
                                        </div>
                                        <div className='display-flex padding-right'>
                                            <p className='hideout-header-value-description batmfa-font'>Total HOLDERS
                                                : </p>
                                            <p className='hideout-header-value batmfa-font'>{totalHolders !== '0' ? totalHolders : '-'}</p>
                                        </div>
                                        <div className='display-flex padding-right-end'>
                                            <p className='hideout-header-value-description batmfa-font'>Total REWARDS
                                                : </p>
                                            <p className='hideout-header-value batmfa-font'>{rewards !== '0' ? rewards : '-'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='display-flex space-betweeen'>
                                <div
                                    className='background-light-blue display-flex space-betweeen hideout-header-description-container-second'>
                                    <div className='neo-ninja'>
                                        <p className='hideout-header-value batmfa-font border-after'>{walletNfts && walletNfts.length > 0 ? walletNfts.at(0).name : (<></>)}</p>
                                    </div>
                                </div>
                                <div className='button-container'>
                                    {wallet ? (
                                        <DisconnectButton className="disconnect-wallet-button" > </DisconnectButton>
                                    ) : (
                                        <ConnectButton className="connect-wallet-button"> </ConnectButton>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        },
        renderArgs: [totalTVL, holdersCount, totalRewards]
    }
    const dashHeaderArea = {
        width: "90%",
        height: "15%",
        left: "5%",
        top: "10%",
        style: {
            borderRadius: "2px",
        },
        classname: "hideout-header",
        render: (area, index, totalTvl, totalHolders, rewards) => {
            return (
                <div>
                    <div className={"hideout-header-divider"}>

                        <button className={"back-button"} style={{width: '50px'}} onClick={() => onMapClick(2)}>
                            <img src={arrowIcon} alt="arrow"></img>
                        </button>

                        <div className={"hideout-header-info"}>
                            <div className="hideout-header-image background-light-blue">
                                <img src={nftProfile} alt="profile"></img>
                            </div>
                            <div className='hideout-header-description-container'>
                                <div
                                    className='background-light-blue display-flex space-betweeen hideout-header-description-container-first'>
                                    <div className='display-flex padding-right'>
                                        <p className='hideout-header-value-description batmfa-font'>Total Value Locked
                                            : </p>
                                        <p className='hideout-header-value batmfa-font'>{totalTvl !== '0' ? totalTVL : "-"}</p>
                                    </div>
                                    <div className='display-flex padding-right'>
                                        <p className='hideout-header-value-description batmfa-font'>Total HOLDERS : </p>
                                        <p className='hideout-header-value batmfa-font'>{totalHolders !== '0' ? totalHolders : '-'}</p>
                                    </div>
                                    <div className='display-flex padding-right-end'>
                                        <p className='hideout-header-value-description batmfa-font'>Total REWARDS : </p>
                                        <p className='hideout-header-value batmfa-font'>{rewards !== '0' ? rewards : '-'}</p>
                                    </div>
                                </div>
                                <div
                                    className='background-light-blue display-flex space-betweeen hideout-header-description-container-second'>
                                    <div className='neo-ninja'>
                                        <p className='hideout-header-value batmfa-font border-after'>{walletNfts && walletNfts.length > 0 ? walletNfts.at(0).name : (<></>)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='button-container'>
                            {wallet ? (
                                <DisconnectButton className="disconnect-wallet-button" ></DisconnectButton>
                            ) : (
                                <ConnectButton className="connect-wallet-button"> </ConnectButton>
                            )}
                        </div>
                    </div>
                    <div className='hideout-mobile-divider'>
                        <button className={"back-button"} onClick={() => onMapClick(2)}>
                            <img src={arrowIcon} alt="arrow"></img>
                        </button>
                        <div style={{width: "100%"}}>
                            <div className={"hideout-header-info"} style={{width: "100%"}}>
                                <div className="hideout-header-image background-light-blue">
                                    <img src={nftProfile} alt="profile"></img>
                                </div>
                                <div className='hideout-header-description-container' style={{width: "100%"}}>
                                    <div className='background-light-blue hideout-header-description-container-first'>
                                        <div className='display-flex padding-right'>
                                            <p className='hideout-header-value-description batmfa-font'>Total Value
                                                Locked : </p>
                                            <p className='hideout-header-value batmfa-font'>{totalTvl !== '0' ? totalTVL : "-"}</p>
                                        </div>
                                        <div className='display-flex padding-right'>
                                            <p className='hideout-header-value-description batmfa-font'>Total HOLDERS
                                                : </p>
                                            <p className='hideout-header-value batmfa-font'>{holdersCount !== '0' ? holdersCount : '-'}</p>
                                        </div>
                                        <div className='display-flex padding-right-end'>
                                            <p className='hideout-header-value-description batmfa-font'>Total REWARDS
                                                : </p>
                                            <p className='hideout-header-value batmfa-font'>{rewards !== '0' ? rewards : '-'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='display-flex space-betweeen'>
                                <div
                                    className='background-light-blue display-flex space-betweeen hideout-header-description-container-second'>
                                    <div className='neo-ninja'>
                                        <p className='hideout-header-value batmfa-font border-after'>{walletNfts && walletNfts.length > 0 ? walletNfts.at(0).name : (<></>)}</p>
                                    </div>
                                </div>
                                <div className='button-container'>
                                    {wallet ? (
                                        <DisconnectButton className="disconnect-wallet-button" > </DisconnectButton>
                                    ) : (
//                                        <WalletKitProvider className="connect-wallet-button"></WalletKitProvider>
                                        <ConnectButton className="connect-wallet-button"> </ConnectButton>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        },
        renderArgs: [totalTVL, holdersCount, totalRewards]
    }
    const landingMapArea = [
        landingHeaderArea,
        {
            left: "37%",
            height: "40%",
            right: "34%",
            width: "30%",
            top: "27%",
            style: {
                bottom: "30%",
            },
            classname: "hideout-desktop",
        },
    ]
    const dashBoardMapArea = [
        dashHeaderArea,
        {
            left: "22%",
            height: "57%",
            width: "55.5%",
            top: "21%",
            style: {},
            classname: "desktop-class",
            render: (area, index) => {
                return (
                                <div className={"dashboard-area dashboard-map-area"}>
                                    <div className='dashboard-context'> 
                                    <div className='timezone-container'>
                                        <div className='timezone-title'> NEO NINJA VAULT PERFORMANCE </div>
                                        <ul className='display-flex'>
                                            <li className='batmfa-font' style={{color: "white"}}>DAY</li>
                                            <li className='batmfa-font' style={{color: "white"}}>/</li>
                                            <li className='batmfa-font' style={{color: "white"}}>MONTH</li>
                                            <li className='batmfa-font' style={{color: "white"}}>/</li>
                                            <li className='batmfa-font' style={{color: "white"}}>ALL TIME</li>
                                        </ul>
                                    </div>
                                    <div className='display-flex justify-content-center'>
                                        <div id="chart">
                                            <Chart options={state.options} series={state.series} type="area"
                                                   height="130%"/>
                                        </div>
                                    </div>
                                    <div className='display-flex justify-content-center'>
                                        <Table columns={columns} dataSource={data}
                                               className="table-bottom table-class"/>
                                    </div>
                                    </div>
                    </div>
                )
            }
        }

    ]
    const [mapArea, setMapArea] = useState(landingMapArea);


    const [poster, setPoster] = useState(hideoutLandingPlaceholder)
    const [view, setView] = useState(hideoutLanding)

    useEffect(() => {
        setMapArea(view === hideoutLanding ? landingMapArea : dashBoardMapArea)
        if (areaButton) setAreaButton(false);
        else setAreaButton(true);
    }, [poster, totalTVL, totalRewards, holdersCount])

    const transitionToDashboard = async () => {
        setMapArea([dashHeaderArea])
        const videoElement = document.getElementById("video-loop")
        setView(hideoutTransition)
        videoElement.load()

        await new Promise(resolve => setTimeout(resolve, 1000))
        setPoster(dashboardPlaceholder)
        setView(dashboard)

        await new Promise(resolve => setTimeout(resolve, 1000))
        videoElement.load()
        setMapArea(dashBoardMapArea)
    }

    const transitionToHideout = async () => {
        setMapArea([dashHeaderArea])
        const videoElement = document.getElementById("video-loop")
        setView(hideoutDashboardPlaceholder)
        videoElement.load()

        await new Promise(resolve => setTimeout(resolve, 1000))
        setView(hideoutLanding)
        await new Promise(resolve => setTimeout(resolve, 900))
        setPoster(hideoutLandingPlaceholder)

        await new Promise(resolve => setTimeout(resolve, 100))

        videoElement.load()
        setMapArea(landingMapArea)
    }

    const onMapClick = async (index) => {
        if (view === hideoutLanding) {
            switch (index) {
                case 1:
                    await transitionToDashboard();
                    break;
                case 2:
                    transitionToHideout();
                    break;
            }
        }
    }


    return (
        <VideoMap muted="muted" id={"video-loop"} src={view} map={mapArea} poster={poster}
                  className={"video-map-main video-hideout-main"} onMapClick={() => onMapClick(1)}/>
    )
}

function formatNumberToUSD(
    amount,
    options = {showCents: false}
) {
    const numFractionDigits = options.showCents ? 2 : 0;
    const numWithFixedFractionDigits = amount.toFixed(numFractionDigits);
    if (numWithFixedFractionDigits === "0" || numWithFixedFractionDigits === "0.00") {
        const numWithFixedSigFigs = amount.toPrecision(3);
        const numDecimals = countDecimals(numWithFixedSigFigs);
        return amount.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: numDecimals,
            maximumFractionDigits: numDecimals,
        });
    }
    return amount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: numFractionDigits,
    });
}

function countDecimals(input) {
    if (input.indexOf(".") === -1) return 0;
    return (input && input.split(".")[1].length) || 0;
}



export default Hideout;

