import React from "react";
import {
    WalletDialogButton,
    WalletDisconnectButton,
} from "@solana/wallet-adapter-material-ui";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import styled from "styled-components";
import "../../App.css";

const ConnectButton = styled(WalletDialogButton)`
  font-weight: bolder;
  cursor: pointer;
  border: none;
  box-shadow: 7px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 4px;
  color: #ffffff;
  transition-property: opacity;
  transition-duration: 0.16s;
  transition-timing-function: ease-in;
  width: 224px;
  height: 70px;
`;

const DisconnectButton = styled(WalletDisconnectButton)`
  font-weight: bolder;
  cursor: pointer;
  border: none;
  box-shadow: 7px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 4px;
  color: #ffffff;
  transition-property: opacity;
  transition-duration: 0.16s;
  transition-timing-function: ease-in;
  width: 224px;
  height: 70px;
`;

const Mute = styled.button`
  font-weight: bolder;
  cursor: pointer;
  border: none;
  box-shadow: 7px 4px 4px rgb(0 0 0 / 25%);
  padding: 10px;
  margin-left: 10px;
  border-radius: 4px;
  z-index: 100;
  color: #ffffff;
  transition-property: opacity;
  transition-duration: 0.16s;
  transition-timing-function: ease-in;
`;

export default function Header() {
    const wallet = useAnchorWallet();

    return (
        <div className="header">
            <div
                className={"wallet-container"}
                style={{
                    display: "flex",
                    position: "absolute",
                    top: 0,
                    alignItems: "center",
                    paddingRight: "20px",
                }}
            >
                {wallet ? (
                    <DisconnectButton className="disconnect-wallet-button" > </DisconnectButton>
                ) : (
                    <ConnectButton className="connect-wallet-button"> </ConnectButton>
                )}
            </div>
        </div>
    );
}