import React from 'react';
import { notification } from 'antd';

export function notify(
    message,
    placement = 'bottomLeft',
) {

    notification[message.type || "info"]({
        message: <span style={{ color: 'black' }}>{message.message}</span>,
        description: (
            <span style={{ color: 'black', opacity: 0.5 }}>{message.description}</span>
        ),
        placement,
        style: {
            backgroundColor: 'white',
            cursor: "pointer"
        },
    });
}
