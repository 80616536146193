import React from "react";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import Mint from "./pages/mint/mint.jsx";
import Hideout from "./pages/hideout/hideout.jsx"
import NftGuardedRoute from "./components/NftGuardedRoute/NftGuardedRoute.jsx";
import store from './store/store'
import { Provider } from 'react-redux';
import {Wallet} from './wallet'
import Layout from "./components/Layout";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {


  return (
      <Wallet>
      <Provider store={store}>
    <div className="App">
      <Switch>
        <Route exact path="/" >
          <Layout>
            <Mint />
          </Layout>
        </Route>
        <Route exact path="/Mint" >
          <Layout>
            <Mint />
          </Layout>
        </Route>

        <NftGuardedRoute path='/Hideout' >
            <Hideout />
        </NftGuardedRoute>
      </Switch>
      <ToastContainer
          position="bottom-left"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
      />
    </div>
      </Provider>
      </Wallet>

  );
}

export default App;
