import { createStore } from "redux";
export const VERIFY = "VERIFY";
const initialState = {
    verified: false,
    tokens: [],

};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case VERIFY:
            return { ...state, verified: true, tokens: action.payload };

        default:
            return state;
    }
};

const store = createStore(reducer);

export default store;