import { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { Snackbar } from "@material-ui/core";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import * as anchor from "@project-serum/anchor";
import "./mint.css";
import background from "../../images/video/NeoCity_v2_Looping.webm";
import zoomInNeoCity from "../../images/video/NeoCity_TRANSITION.webm";
import zoomOutNeoCity from "../../images/video/NeoCity_TRANSITION_OUT.webm";
import showNeoPoster from "../../images/video/show_neo_poster_v2.png";
import showCityPoster from "../../images/background.png";
import showNeoCity from "../../images/video/HIDEOUT_ENTRANCE_STILL.webm";
import transitionVideo from "../../images/video/Transition_Hideout_Entrance_to_Landing_Page.webm";
import arrowIcon from "../../images/arrow_icon.png";
import backgroundBackground from "../../images/background_background.png";
import { useHistory } from "react-router-dom";
import {
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
} from "../../candy-machine";
import { VideoMap } from "react-video-map/dist/index";
import { notify } from "../../utils/notification";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
import axios from "axios";
import { useDispatch } from "react-redux";
import { VERIFY } from "../../store/store"; 

const apiUrl = process.env.REACT_APP_NEO_NINJA_API;
const programUuid = process.env.REACT_APP_CANDY_MACHINE_PROGRAM_ID;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST;
const connection = new anchor.web3.Connection(rpcHost);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const CounterText = styled.span``; // add your styles here

const Mint = () => {
  const [balance, setBalance] = useState();
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);

  const [view, setView] = useState(background);

  const [muted, setMuted] = useState(true);
  const [poster, setPoster] = useState(showCityPoster);
  const [showGif, setShowGif] = useState(true);
  const history = useHistory();
  const handleOnClick = useCallback(() => history.push("/hideout"), [history]);
  const dispatch = useDispatch();

  const playMusic = () => {
    setMuted((prev) => !prev);
    const audio = document.getElementById("music-embed");
    audio.play();
  };
  useEffect(() => {
    const audio = document.getElementById("music-embed");

    audio.muted = muted;
  }, [muted]);

  useEffect(() => {
    // await setShowGif(false);
    if (view === background) {
      setShowGif(true);
    } else {
      setShowGif(false);
    }
  }, [view]);

  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState();

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const candyMachine = await getCandyMachineState(
        wallet,
        programUuid,
        connection
      );

      setItemsAvailable(candyMachine.state.itemsAvailable);
      setItemsRemaining(candyMachine.state.itemsRemaining);
      setItemsRedeemed(candyMachine.state.itemsRedeemed);

      setIsSoldOut(candyMachine.state.itemsRemaining === 0);
      setCandyMachine(candyMachine);
    })();
  };

  const onMint = async () => {
    try {
      if (!wallet) {
        notify({
          message: "Connect your wallet to mint",
          type: "info",
        });
        return;
      }
      if (isSoldOut) {
        notify({
          message: "Sold out",
          type: "error",
        });
        return;
      }
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          wallet.publicKey,
          connection
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          txTimeout,
          connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          notify({
            message: "Congratulations! Mint succeeded!",
            type: "success",
          });
        } else {
          notify({
            message: "Mint failed! Please try again!",
            type: "error",
          });
        }
      }
    } catch (error) {
      console.error(error);
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      notify({
        message: message,
        type: "error",
      });
    } finally {
      if (wallet) {
        const balance = await connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  const handleMintClick = (e) => {
    e.preventDefault();

    onMint();
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet]);

  useEffect(refreshCandyMachineState, [wallet]);
  //
  // useEffect(() => {
  //   document
  //     .getElementById("mintArea")
  //     .addEventListener("click", handleMintClick);
  //
  //   return () => {
  //     document
  //       .getElementById("mintArea")
  //       .removeEventListener("click", handleMintClick);
  //   };
  // }, [wallet, candyMachine, isSoldOut]);

  const mapArea = [
    {
      left: "11%",
      height: "35%",
      width: "25%",
      top: "none",
      style: {
        transform: "rotateY(359deg) skew(360deg, 358deg)",
        bottom: "20%",
      },
    },
    {
      width: "11.2%",
      height: "20%",
      left: "12.6%",
      top: "10%",
      style: {
        transform: "rotateY(359deg) skew(360deg, 2deg)",
        borderRadius: "2px",
      },
    },
    {
      left: "38%",
      height: "31%",
      width: "6%",
      top: "none",
      style: {
        bottom: "25%",
      },
    },
    {
      left: "58%",
      height: "36%",
      width: "31%",
      top: "none",
      style: {
        bottom: "16%",
        transform: "rotateY(6deg) skew(0deg, 1deg)",
      },
    },
    {
      left: "61%",
      height: "25%",
      width: "11%",
      top: "10%",
      style: {},
    },
    {
      left: "49%",
      height: "12%",
      width: "11%",
      top: "20%",
      style: {},
    },
    {
      left: "77%",
      height: "28%",
      width: "12%",
      top: "14%",
      style: {},
    },
    {
      width: "8%",
      height: "33%",
      left: "89.5%",
      top: "61%",
      style: {},
    },
    {
      width: "6%",
      height: "9%",
      left: "51%",
      top: "44%",
      style: {},
    },
  ];

  const neoMapArea = [
    {
      width: "19%",
      height: "34%",
      left: "53%",
      top: "60%",
      render: () => {
        return <div></div>;
      },
    },
    {
      width: "8.2%",
      height: "10%",
      left: "1.6%",
      top: "5%",
      display: "flex",
      render: () => {
        return (
          <button className={"back-button"}>
            <img src={arrowIcon} alt="arrow" />
          </button>
        );
      },
    },
  ];
  const checkWalletNft = async () => {
    if (!wallet) throw "Connect your wallet to verify";
    let userNfts = await axios.post(
      apiUrl + "/api/gate/check/" + wallet.publicKey.toBase58()
    );
    if (!userNfts) throw "An Unknown Error occurred";
    if (!userNfts.data.success)
      throw "No Neo Ninja nfts found associated with this wallet";

    dispatch({ type: VERIFY, payload: userNfts.data.tokens });
  };
  const showVerificationErrorAlert = (e) => {
    toast.error(e);
  };

  const onMapClick = async (area, index) => {
    switch (index) {
      case 0:
        //        poster === showNeoPoster? showChangeVideo() : onMint();
        poster === showNeoPoster
          ? checkWalletNft()
              .then(showChangeVideo)
              .catch(showVerificationErrorAlert)
          : onMint();
        break;
      case 1:
        poster === showNeoPoster ? showZoomOutVideo() : window.open(process.env.PUBLIC_URL + "/images/rarity_sheet.jpg");
        break;
      case 2:
        window.open("https://docs.ninjaprotocol.io/nft/neo-ninja");
        break;
      case 3:
        window.open("https://market.ninjaprotocol.io");
        break;
      case 4:
        window.open("https://raydium.io");
        break;
      case 5:
        window.open(
          "https://raydium.io/liquidity/add/?coin0=FgX1WD9WzMU3yLwXaFSarPfkgzjLb2DZCqmkx9ExpuvJ&coin1=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"
        );
        break;
      case 6:
        window.open("https://ninjaprotocol.io");
        break;
      case 7:
        playMusic();
        break;
      case 8:
        await showZoomInVideo();
        break;
    }
  };

  const showZoomInVideo = async () => {
    const videoElement1 = document.getElementById("video-loop");
    setSelectMapArea([]);
    setPoster(showCityPoster);
    setView(zoomInNeoCity);
    videoElement1.load()
    await new Promise((resolve) => setTimeout(resolve, 1500));
    setPoster(showNeoPoster);
    setView(showNeoCity);

    await new Promise((resolve) => setTimeout(resolve, 1470));
    videoElement1.load();
    setSelectMapArea(neoMapArea);
  };

  const showZoomOutVideo = async () => {
    let videoElement = document.getElementById("video-loop");
    setSelectMapArea([]);
    setView(zoomOutNeoCity);
    videoElement.load();

    await new Promise((resolve) => setTimeout(resolve, 1500));
    setPoster(showCityPoster);

    await new Promise((resolve) => setTimeout(resolve, 1470));
    setView(background);
    videoElement.load();
    setSelectMapArea(mapArea);
  };

  const showChangeVideo = async () => {
    let videoElement = document.getElementById("video-loop");
    setView(transitionVideo);
    videoElement.load();

    await new Promise((resolve) => setTimeout(resolve, 2000));
    //        setPoster(showCityPoster)
    //        setView(background)

    //        await new Promise(resolve => setTimeout(resolve, 1500))
    //        videoElement.load()
    //        setSelectMapArea(mapArea)

    handleOnClick();
  };

  const [step, setStep] = useState(1);
  const [scrollAnimate, setScrollAnimate] = useState(true);
  const [videoLoadFirst, setVideoLoadFirst] = useState(true);
  const [videoLoadSecond, setVideoLoadSecond] = useState(true);
  const [videoLoadThird, setVideoLoadThird] = useState(true);
  const [selectMapArea, setSelectMapArea] = useState(mapArea);

  const toScroll = (event, height, stepNumber) => {
    setScrollAnimate(false);
    event.target.scrollTop = height;
    setStep(stepNumber);
    setTimeout(() => {
      setScrollAnimate(true);
    }, 500);
  };

  const handleScroll = (event) => {
    if (scrollAnimate) {
      const scroll = event.target.scrollTop;
      const windowHeight = window.innerHeight;
      const scrollSize = 10;

      if (scroll >= scrollSize && step === 1) {
        toScroll(event, windowHeight, step + 1);
      } else if (scroll > windowHeight + scrollSize && step == 2) {
        toScroll(event, windowHeight * 2, step + 1);
      } else if (step === 2 && scroll <= windowHeight - scrollSize) {
        toScroll(event, 0, step - 1);
      } else if (step === 3 && scroll <= windowHeight * 2 - scrollSize) {
        toScroll(event, windowHeight, step - 1);
      }
    }
  };

  const setAutoplay = () => {
    let vidElement = document.getElementById("video-loop");
    if (vidElement.paused) console.log(vidElement.play());
  };

  setInterval(setAutoplay, 50000);
  return (
    <div
      className="mint"
      onScroll={handleScroll}
      style={{
        overflowY: scrollAnimate ? "scroll" : "hidden",
        backgroundImage: `url("${backgroundBackground}")`,
      }}
    >
      {videoLoadFirst && videoLoadSecond && videoLoadThird && (
        <div className="roading-component">
          <ReactLoading type="spinningBubbles" color="#fff"></ReactLoading>
        </div>
      )}
      <VideoMap
        muted="muted"
        id={"video-loop"}
        src={view}
        map={selectMapArea}
        className={"video-map-main"}
        poster={poster}
        onMapClick={onMapClick}
        onLoadedData={() => setVideoLoadFirst(false)}
      />
      <VideoMap
        muted="muted"
        id={"video-loop"}
        autoPlay
        loop
        src={view}
        map={selectMapArea}
        poster={poster}
        className={"video-map-main"}
        onMapClick={onMapClick}
        onLoadedData={() => setVideoLoadSecond(false)}
      />
      <VideoMap
        muted="muted"
        id={"video-loop"}
        autoPlay
        loop
        src={view}
        map={selectMapArea}
        poster={poster}
        className={"video-map-main"}
        onMapClick={onMapClick}
        onLoadedData={() => setVideoLoadThird(false)}
      />

      <main>
        <div className={"mint-container"}>
          <div className={"mint-info"}>
            {wallet && view === background && (
              <p className="mint-info-hover">
                Total Available: {itemsAvailable}
              </p>
            )}

            {wallet && view === background && (
              <p className="mint-info-hover">Redeemed: {itemsRedeemed}</p>
            )}

            {wallet && view === background && (<p>Remaining: {itemsRemaining}</p>)}
          </div>
          {/* <button
            className={"mint-button"}
            onClick={onMint}
            variant="contained"
        >
          {!wallet ? 'MINT' : isSoldOut ? "SOLD OUT" : isMinting ? <CircularProgress /> : "MINT"}
        </button> */}
        </div>

        <Snackbar open={alertState.open} autoHideDuration={6000}></Snackbar>
      </main>
    </div>
  );
};

const renderCounter = ({ days, hours, minutes, seconds, completed }) => {
  return (
    <CounterText>
      {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};
export default Mint;
